import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
import PromptTip from "@/components/LoginDialog";
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
	"/home",
	"/gw_shijian",
	"/home_hz",
	"/home_eightclock",
	"/home_zt",
	"/wz_detail",
];
// no redirect whitelist
const blockList = [
	"/my/Individual",
	"/my/mymessage",
	"/my/realname",
	"/my/follow",
	"/my/collect",
	"/my/events",
];
router.beforeEach(async (to, from, next) => {
	// start progress bar
	NProgress.start();

	// set page title
	//   document.title = getPageTitle(to.meta.title)
	// console.log("path:" + to.path);
	// console.log("url:" + to.fullPath);
	// console.log("title:" + to.query.title);
	var title = "MetaEra";
	var sitename = "MetaEra";
	var desc =
		"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。";
	var fullPath = "https://www.metaera.hk" + to.fullPath;
	if (to.path == "/gw_detail" || to.path == "/kuaixun_wz") {
		if (to.query.title) {
			title = to.query.title;
		}
		if (to.query.desc) {
			desc = to.query.desc;
		}
	}
	document.title = title;

	let ogtitle = document.querySelector('meta[property="og:title"]');
	if (!ogtitle) {
		ogtitle = document.createElement("meta");
		ogtitle.setAttribute("property", "og:title");
		document.head.appendChild(ogtitle);
	}
	ogtitle.setAttribute("content", title == null ? sitename : title);

	let ogdescription = document.querySelector('meta[property="og:description"]');
	if (!ogdescription) {
		ogdescription = document.createElement("meta");
		ogdescription.setAttribute("property", "og:description");
		document.head.appendChild(ogdescription);
	}
	ogdescription.setAttribute("content", desc == null ? sitename : desc);

	let ogimage = document.querySelector('meta[property="og:image"]');
	if (!ogimage) {
		ogimage = document.createElement("meta");
		ogimage.setAttribute("property", "og:image");
		document.head.appendChild(ogimage);
	}
	ogimage.setAttribute(
		"content",
		"https://zy.metaera.media/assets/images/pclogo.jpg"
	);

	let ogurl = document.querySelector('meta[property="og:url"]');
	if (!ogurl) {
		ogurl = document.createElement("meta");
		ogurl.setAttribute("property", "og:url");
		document.head.appendChild(ogurl);
	}
	ogurl.setAttribute("content", fullPath);

	let ogsite_name = document.querySelector('meta[property="og:site_name"]');
	if (!ogsite_name) {
		ogsite_name = document.createElement("meta");
		ogsite_name.setAttribute("property", "og:site_name");
		document.head.appendChild(ogsite_name);
	}
	ogsite_name.setAttribute("content", title == null ? sitename : title);

	let ogtype = document.querySelector('meta[property="og:type"]');
	if (!ogtype) {
		ogtype = document.createElement("meta");
		ogtype.setAttribute("property", "og:type");
		document.head.appendChild(ogtype);
	}
	ogtype.setAttribute("content", "article");

	let metaTitle = document.querySelector("title");
	if (!metaTitle) {
		metaTitle = document.createElement("title");
		document.head.appendChild(metaTitle);
	}
	metaTitle.setAttribute("content", title == null ? sitename : title);

	let author = document.querySelector('meta[name="author"]');
	if (!author) {
		author = document.createElement("meta");
		author.setAttribute("name", "author");
		document.head.appendChild(author);
	}
	author.setAttribute("content", title == null ? sitename : title);

	let description = document.querySelector('meta[name="description"]');
	if (!description) {
		description = document.createElement("meta");
		description.setAttribute("name", "description");
		document.head.appendChild(description);
	}
	description.setAttribute("content", desc == null ? sitename : desc);

	let twittertitle = document.querySelector('meta[name="twitter:title"]');
	if (!twittertitle) {
		twittertitle = document.createElement("meta");
		twittertitle.setAttribute("name", "twitter:title");
		document.head.appendChild(twittertitle);
	}
	twittertitle.setAttribute("content", title == null ? sitename : title);

	let twitterdescription = document.querySelector(
		'meta[name="twitter:description"]'
	);
	if (!twitterdescription) {
		twitterdescription = document.createElement("meta");
		twitterdescription.setAttribute("name", "twitter:description");
		document.head.appendChild(twitterdescription);
	}
	twitterdescription.setAttribute("content", desc == null ? sitename : desc);

	let twittercard = document.querySelector('meta[name="twitter:card"]');
	if (!twittercard) {
		twittercard = document.createElement("meta");
		twittercard.setAttribute("name", "twitter:card");
		document.head.appendChild(twittercard);
	}
	twittercard.setAttribute("content", "summary");

	let twitterimage = document.querySelector('meta[name="twitter:image"]');
	if (!twitterimage) {
		twitterimage = document.createElement("meta");
		twitterimage.setAttribute("name", "twitter:image");
		document.head.appendChild(twitterimage);
	}
	twitterimage.setAttribute(
		"content",
		"https://zy.metaera.media/assets/images/pclogo.jpg"
	);

	let twitterurl = document.querySelector('meta[name="twitter:url"]');
	if (!twitterurl) {
		twitterurl = document.createElement("meta");
		twitterurl.setAttribute("name", "twitter:url");
		document.head.appendChild(twitterurl);
	}
	twitterurl.setAttribute("content", fullPath);

	// 触发 Twitter Cards 更新
	if (typeof twttr !== "undefined") {
		twttr.widgets.load();
	}

	// determine whether the user has logged in
	const hasToken = getToken();

	if (hasToken) {
		// determine whether the user has obtained his permission roles through getInfo
		const hasGetUserInfo = store.getters.name;
		if (hasGetUserInfo) {
			next();
		} else {
			try {
				// get user info
				// note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
				await store.dispatch("user/getInfo");
				// generate accessible routes map based on roles

				// hack method to ensure that addRoutes is complete
				// set the replace: true, so the navigation will not leave a history record
				next({ ...to, replace: true });
			} catch (error) {
				await store.dispatch("user/resetToken");
				Message.error(error || "Has Error");
				next(`/home`);
				NProgress.done();
			}
		}
	} else {
		/* has no token*/

		if (blockList.indexOf(to.path) !== -1) {
			next(`/home`);
			PromptTip({ dialogVisible: true, showStatus: 1 });
			NProgress.done();
		} else {
			// other pages that do not have permission to access are redirected to the login page.
			next();
		}
	}
});

router.afterEach(() => {
	// finish progress bar
	NProgress.done();
});
